import {
  AccessTime,
  AccountTree,
  AddBox,
  AltRoute,
  Announcement,
  Apps,
  ArrowDropDownCircle,
  Article,
  AssignmentInd,
  Business,
  CalendarToday,
  Campaign,
  CancelScheduleSend,
  CloudDone,
  CloudDownload,
  CloudUpload,
  Compare,
  ContactMail,
  Contacts,
  CrisisAlert,
  DeviceHub,
  DevicesOther,
  DirectionsRun,
  Dns,
  DynamicFeed,
  Email,
  Equalizer,
  ErrorOutline,
  Explore,
  Extension,
  Folder,
  FormatPaint,
  Forum,
  Grading,
  Group,
  History,
  Home,
  Inbox,
  Key,
  Label,
  Language,
  Lock,
  Login,
  MailOutline,
  ManageHistory,
  Map,
  MonetizationOn,
  Notifications,
  Pages,
  Pageview,
  Password,
  Payment,
  People,
  PermMedia,
  Person,
  PersonAdd,
  PersonOff,
  PersonOutline,
  PieChart,
  PlaylistAddCheck,
  PriceChange,
  Receipt,
  Score,
  Security,
  Send,
  Settings,
  SettingsApplications,
  SettingsInputComponent,
  Share,
  Sms,
  StackedBarChart,
  Subtitles,
  SupervisedUserCircle,
  Sync,
  TaskAlt,
  Timeline,
  ThreeSixty,
  Traffic,
  Tune,
  Web,
  Wysiwyg,
  Cast,
  ContactEmergency,
} from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import React from 'react';

export const iconMappings = {
  accessTime: <AccessTime />,
  accountSetup: <SettingsApplications />,
  accountTree: <AccountTree />,
  addBox: <AddBox />,
  altRoute: <AltRoute sx={{ transform: 'rotate(90deg)' }} />,
  analytics: <StackedBarChart />,
  announcement: <Announcement />,
  apps: <Apps />,
  arrowDropDownCircle: <ArrowDropDownCircle />,
  article: <Article />,
  assignmentInd: <AssignmentInd />,
  attribution: <ThreeSixty />,
  bounced: <PersonOff />,
  business: <Business />,
  calendar: <CalendarToday />,
  campaign: <Campaign />,
  cancelScheduleSend: <CancelScheduleSend />,
  cast: <Cast />,
  cloudDownload: <CloudDownload />,
  cloudDone: <CloudDone />,
  cloudUpload: <CloudUpload />,
  compare: <Compare />,
  contact: <Person />,
  contactEmergency: <ContactEmergency />,
  contactMail: <ContactMail />,
  contacts: <Contacts />,
  deviceHub: <DeviceHub />,
  devicesOther: <DevicesOther />,
  directionsRun: <DirectionsRun />,
  dns: <Dns />,
  dynamicFeed: <DynamicFeed />,
  email: <Email />,
  equalizer: <Equalizer />,
  errorOutline: <ErrorOutline />,
  explore: <Explore />,
  extension: <Extension />,
  folder: <Folder />,
  formatPaintIcon: <FormatPaint />,
  forum: <Forum />,
  grading: <Grading />,
  group: <Group />,
  history: <History />,
  home: <Home />,
  inbox: <Inbox />,
  key: <Key />,
  language: <Language />,
  label: <Label />,
  lead: <CrisisAlert />,
  listIcon: <ListIcon />,
  login: <Login />,
  lock: <Lock />,
  mailOutline: <MailOutline />,
  manageHistory: <ManageHistory />,
  map: <Map />,
  notifications: <Notifications />,
  opportunities: <MonetizationOn />,
  pages: <Pages />,
  pageview: <Pageview />,
  password: <Password />,
  payment: <Payment />,
  people: <People />,
  actions: <DirectionsRun />,
  permMedia: <PermMedia />,
  personAdd: <PersonAdd />,
  personOutline: <PersonOutline />,
  pieChart: <PieChart />,
  playlistAddCheck: <PlaylistAddCheck />,
  pricePlan: <PriceChange />,
  receipt: <Receipt />,
  score: <Score />,
  security: <Security />,
  send: <Send />,
  settings: <Settings />,
  settingsApplications: <SettingsApplications />,
  settingsInputComponent: <SettingsInputComponent />,
  share: <Share />,
  sms: <Sms />,
  subtitles: <Subtitles />,
  sunset: <PersonOff />,
  supervisedUserCircle: <SupervisedUserCircle />,
  sync: <Sync />,
  taskAlt: <TaskAlt />,
  timeline: <Timeline />,
  traffic: <Traffic />,
  tune: <Tune />,
  unsubscribed: <PersonOff />,
  web: <Web />,
  wysiwyg: <Wysiwyg />,
};

export const navItemsWorkflows = [
  {
    url: '/marketing-center/automations',
    label: 'Automations',
    icon: 'deviceHub',
  },
  {
    url: '/reporting/dashboards/automation-comparison',
    label: 'Automation Comparison',
    icon: 'equalizer',
  },
  {
    url: '/reporting/dashboards/automation-overview',
    label: 'Automation Overview',
    icon: 'equalizer',
  },
  {
    url: '/reporting/dashboards/automation-performance',
    label: 'Automation Performance',
    icon: 'equalizer',
  },
];

export const navItemsEmails = [
  {
    url: '/marketing-center/emails',
    label: 'Emails',
    icon: 'email',
  },
  {
    url: '/marketing-center/emails/dashboard',
    label: 'Email Performance',
    icon: 'equalizer',
  },
  {
    url: '/marketing-center/email-templates',
    label: 'Email Templates',
    icon: 'mailOutline',
  },
  {
    url: '/marketing-center/emails/email-preferences',
    label: 'Email Testing Preferences',
    icon: 'settings',
  },
];

export const recentItemsDynamicsIntegration = [
  {
    url: '/setup/account/integrations/dynamics/field-mappings#lead',
    label: 'Field Mappings: D365 Leads',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/dynamics/field-mappings#contact',
    label: 'Field Mappings: D365 Contacts',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/dynamics/field-mappings#account',
    label: 'Field Mappings: D365 Accounts',
    icon: 'altRoute',
  },
];

export const navItemsDynamicsIntegration = [
  {
    url: '/setup/account/integrations/dynamics/settings',
    label: 'D365 Sync Settings',
    icon: 'settings',
  },
  {
    url: '/setup/account/integrations/dynamics/field-mappings',
    label: 'Field Mappings: D365 Leads',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/dynamics/errors',
    label: 'D365 Sync Errors',
    icon: 'errorOutline',
  },
];

export const recentItemsSalesforceIntegration = [
  {
    url: '/setup/account/integrations/salesforce/field-mappings#lead',
    label: 'Salesforce Field Mappings: Leads',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/salesforce/field-mappings#contact',
    label: 'Salesforce Field Mappings: Contacts',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/salesforce/field-mappings#account',
    label: 'Salesforce Field Mappings: Accounts',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/salesforce/field-mappings',
    label: 'Salesforce Field Mappings: Leads',
    icon: 'altRoute',
  },
];

export const recentItemsSalesforceActions = [
  {
    url: '/setup/account/integrations/salesforce/actions#lead',
    label: 'Salesforce Sync Actions: Leads',
    icon: 'directionsRun',
  },
  {
    url: '/setup/account/integrations/salesforce/actions#contact',
    label: 'Salesforce Sync Actions: Contacts',
    icon: 'directionsRun',
  },
];

export const navItemsSalesforceIntegration = [
  {
    url: '/setup/account/integrations/salesforce/auth',
    label: 'Salesforce Authorization',
    icon: 'lock',
  },
  {
    url: '/setup/account/integrations/salesforce/settings',
    label: 'Salesforce Sync Settings',
    icon: 'settings',
  },
  {
    url: '/setup/account/integrations/salesforce/field-mappings',
    label: 'Salesforce Field Mappings',
    icon: 'altRoute',
  },
  // {
  //   url: '/setup/account/integrations/salesforce/actions#contact',
  //   label: 'Salesforce Sync Actions',
  //   icon: 'directionsRun',
  // },
  {
    url: '/setup/account/integrations/salesforce/errors',
    label: 'Salesforce Sync Errors',
    icon: 'errorOutline',
  },
];

export const navItemsIfsIntegration = [
  // todo
  // {
  //   url: '/setup/account/integrations/ifs/auth',
  //   label: 'Authorization',
  //   icon: 'lock',
  // },
  {
    url: '/setup/account/integrations/ifs/settings',
    label: 'Settings',
    icon: 'settings',
  },
  // todo
  // {
  //   url: '/setup/account/integrations/ifs/field-mappings',
  //   label: 'Field Mappings',
  //   icon: 'altRoute',
  // },
  {
    url: '/setup/account/integrations/ifs/sync-status',
    label: 'Sync Status',
    icon: 'manageHistory',
  },
  {
    url: '/setup/account/integrations/ifs/sync-history',
    label: 'Sync History',
    icon: 'history',
  },
  {
    url: '/setup/account/integrations/ifs/actions#contact',
    label: 'Sync Actions',
    icon: 'directionsRun',
  },
  // todo
  // {
  //   url: '/setup/account/integrations/ifs/errors',
  //   label: 'Sync Errors',
  //   icon: 'errorOutline',
  // },
];

export const recentItemsSugarCrmIntegration = [
  {
    url: '/setup/account/integrations/sugarcrm/field-mappings#lead',
    label: 'Field Mappings: SugarCRM Leads',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/sugarcrm/field-mappings#contact',
    label: 'Field Mappings: SugarCRM Contacts',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/sugarcrm/field-mappings#account',
    label: 'Field Mappings: SugarCRM Accounts',
    icon: 'altRoute',
  },
];

export const navItemsSugarCrmIntegration = [
  {
    url: '/setup/account/integrations/sugarcrm/settings',
    label: 'Settings',
    icon: 'settings',
  },
  {
    url: '/setup/account/integrations/sugarcrm/field-mappings',
    label: 'Field Mappings: SugarCRM Leads',
    icon: 'altRoute',
  },
  {
    url: '/setup/account/integrations/sugarcrm/errors',
    label: 'Sync Errors',
    icon: 'errorOutline',
  },
];

export const navItemsTextMessages = [
  {
    url: '/marketing-center/sms',
    label: 'Text Messages',
    icon: 'sms',
  },
  {
    url: '/marketing-center/automations',
    label: 'Automations',
    icon: 'deviceHub',
  },
];

export const navItemsFormsLandingPages = [
  {
    url: '/marketing-center/landing-pages',
    label: 'Landing Pages',
    icon: 'web',
  },
  {
    url: '/settings/account/landing-page-domains/drag-and-drop',
    label: 'Landing Page Domains',
    icon: 'settingsApplications',
  },
  {
    url: '/reporting/dashboards/conversionsiframe',
    label: 'FormSubmissions Dashboard',
    icon: 'equalizer',
  },
  {
    url: '/marketing-center/classic-forms',
    label: 'Forms',
    icon: 'dns',
  },
  {
    url: '/marketing-center/mapped-forms',
    label: 'Mapped Forms',
    icon: 'dns',
  },
];

export const navItemsForms = [
  {
    url: '/marketing-center/forms',
    label: 'Forms',
    icon: 'dns',
  },
  {
    url: '/marketing-center/form-submissions',
    label: 'Form Submissions',
    icon: 'listIcon',
  },
];

export const leftNavItemsSocialPosting = [
  {
    url: '/marketing-center/social/accounts',
    label: 'Social Accounts',
    icon: 'personAdd',
  },
  {
    url: '/marketing-center/social/posts/create',
    label: 'Schedule Posts',
    icon: 'accessTime',
  },
  {
    url: '/marketing-center/social/calendar',
    label: 'Calendar View',
    icon: 'calendar',
  },
  {
    url: '/marketing-center/social/list-view',
    label: 'List View',
    icon: 'listIcon',
  },
  {
    url: '/reporting/dashboards/social-page-overview',
    label: 'Social Page Overview',
    icon: 'equalizer',
  },
];

export const navItemsLists = [
  {
    url: '/marketing-center/lists',
    label: 'Marketing Lists',
    icon: 'listIcon',
  },
  {
    url: '/marketing-center/subscriptions',
    label: 'Subscription Lists',
    icon: 'playlistAddCheck',
  },
  {
    url: '/marketing-list/dashboard',
    label: 'Marketing List Dashboard',
    icon: 'equalizer',
  },
  {
    url: '/subscription-list/dashboard',
    label: 'Subscription List Dashboard',
    icon: 'equalizer',
  },
];

export const navItemsContactsAccounts = [
  {
    url: '/accounts',
    label: 'Accounts',
    icon: 'business',
  },
  {
    url: '/contacts',
    label: 'Contacts',
    icon: 'people',
  },
  {
    url: '/custom-objects',
    label: 'Custom Objects',
    icon: 'accountTree',
  },
  {
    url: '/contacts/import',
    label: 'Import Contacts',
    icon: 'cloudUpload',
  },
  {
    url: '/contact-imports',
    label: 'Import History',
    icon: 'cloudDone',
  },
];

export const navItemsPersonalSettings = [
  {
    url: '/settings/personal/profile',
    label: 'Your Profile',
    icon: 'personOutline',
  },
  {
    url: '/settings/personal/password-change',
    label: 'Change Your Password',
    icon: 'password',
  },
  {
    url: '/settings/personal/two-factor-authentication',
    label: 'Two Factor Authentication',
    icon: 'key',
  },
  {
    url: '/settings/personal/preferences',
    label: 'General Preferences',
    icon: 'tune',
  },
  {
    url: '/settings/personal/email-testing-preferences',
    label: 'Email Testing Preferences',
    icon: 'settings',
  },
];

export const recentItemsAccountSetup = [
  {
    url: '/settings/account/setup',
    label: 'Account Setup',
    icon: 'settingsApplications',
  },
  {
    url: '/settings/account/setup#file-manager',
    label: 'File Manager',
    icon: 'settingsApplications',
  },
  {
    url: '/settings/account/landing-page-domains/drag-and-drop',
    label: 'Landing Page Domains',
    icon: 'settingsApplications',
  },
  {
    url: '/settings/account/setup#tracking-beacon',
    label: 'Tracking Beacon',
    icon: 'settingsApplications',
  },
];

export const navItemsAccountSettings = [
  {
    url: '/settings/account/profile',
    label: 'Account Profile',
    icon: 'business',
  },
  {
    url: '/settings/account/setup',
    label: 'Account Setup',
    icon: 'settingsApplications',
  },
  {
    url: '/settings/account/automations',
    label: 'Automation Settings',
    icon: 'deviceHub',
  },
  {
    url: '/settings/account/billing#price-plan',
    label: 'Billing',
    icon: 'payment',
  },
  {
    url: '/settings/account/brand-kits',
    label: 'Brand Kits',
    icon: 'formatPaintIcon',
  },
  {
    url: '/settings/account/custom-fields/contact',
    label: 'Custom Fields',
    icon: 'extension',
  },
  {
    url: '/settings/account/custom-objects',
    label: 'Custom Object Definitions',
    icon: 'accountTree',
  },
  {
    url: '/settings/account/drop-downs',
    label: 'Drop Downs',
    icon: 'arrowDropDownCircle',
  },
  {
    url: '/setup/account/integrations/dynamics/settings',
    label: 'Dynamics 365',
    icon: 'settingsInputComponent',
  },
  {
    url: '/settings/account/integrations',
    label: 'Integrations',
    icon: 'settingsInputComponent',
  },
  {
    url: '/settings/account/lead-stages',
    label: 'Lead Stages',
    icon: 'explore',
  },
  {
    url: '/settings/account/landing-page-domains/drag-and-drop',
    label: 'Landing Page Domains',
    icon: 'settingsApplications',
  },
  {
    url: '/settings/account/oauth-apps',
    label: 'oAuth Apps',
    icon: 'apps',
  },
  {
    url: '/settings/account/password-policy',
    label: 'Password/Security Policy',
    icon: 'security',
  },
  {
    url: '/setup/account/integrations/salesforce/settings',
    label: 'Salesforce',
    icon: 'settingsInputComponent',
  },
  {
    url: '/marketing-center/social/accounts',
    label: 'Social Accounts',
    icon: 'share',
  },
  // {
  //   url: '/settings/account/user-profiles',
  //   label: 'Roles & Permissions',
  //   icon: 'assignmentInd',
  // },
  // {
  //   url: '/settings/account/tags',
  //   label: 'Tags',
  //   icon: 'label',
  // },
  {
    url: '/settings/account/users',
    label: 'Users',
    icon: 'people',
  },
];

export const recentItemsIntegrations = [
  {
    url: '/setup/account/integrations/alpheid/setup',
    label: 'Integration: Alpheid',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/feed-otter/setup',
    label: 'Integration: Feed Otter',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/gmail/setup',
    label: 'Integration: Gmail',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/goToWebinar/setup',
    label: 'Integration: GoToWebinar',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/magento/setup',
    label: 'Integration: Magento',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/outlook/setup',
    label: 'Integration: Outlook',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/shopify/setup',
    label: 'Integration: Shopify',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/twilio/setup',
    label: 'Integration: Twilio',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/vidyard/setup',
    label: 'Integration: Vidyard',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/vimeo/setup',
    label: 'Integration: Vimeo',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/wistia/setup',
    label: 'Integration: Wistia',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/wordpress/setup',
    label: 'Integration: WordPress',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/youtube/setup',
    label: 'Integration: YouTube',
    icon: 'settingsInputComponent',
  },
  {
    url: '/setup/account/integrations/zapier/setup',
    label: 'Integration: Zapier',
    icon: 'settingsInputComponent',
  },
];

export const recentItemsCustomFields = [
  {
    url: '/settings/account/custom-fields/contact',
    label: 'Contact Custom Fields',
    icon: 'extension',
  },
  {
    url: '/settings/account/custom-fields/account',
    label: 'Account Custom Fields',
    icon: 'extension',
  },
];

export const recentItemsBillingSubTabs = [
  {
    url: '/settings/account/billing#price-plan',
    label: 'Price Plan',
    icon: 'pricePlan',
  },
  {
    url: '/settings/account/billing#recent-bills',
    label: 'Recent Bills',
    icon: 'receipt',
  },
  {
    url: '/settings/account/billing#billing-recipients',
    label: 'Billing Recipients',
    icon: 'calendar',
  },
  {
    url: '/settings/account/billing#payment-info',
    label: 'Payment Info',
    icon: 'payment',
  },
];

export const navItemsMarketingCenter = [
  {
    url: '/marketing-center/calendar',
    label: 'Calendar',
    icon: 'calendar',
  },
  {
    url: '/marketing-center/Automations',
    label: 'Automations',
    icon: 'deviceHub',
  },
  {
    url: '/marketing-center/emails',
    label: 'Emails',
    icon: 'email',
    createNewUrl: '/marketing-center/emails/createEdit',
    createNewLabel: 'Email',
  },
  {
    url: '/marketing-center/emails/dashboard',
    label: 'Email Performance Dashboard',
    icon: 'equalizer',
  },
  {
    url: '/marketing-center/file-manager',
    label: 'File Manager',
    icon: 'folder',
  },
  {
    url: '/marketing-center/classic-forms',
    label: 'Forms',
    icon: 'dns',
    createNewUrl: '/marketing-center/classic-forms/createEdit',
    createNewLabel: 'Form',
  },
  {
    url: '/marketing-center/forms',
    label: 'New! Goldilocks Form Builder',
    icon: 'dns',
    createNewUrl: '/marketing-center/forms/createEdit',
    createNewLabel: 'Form',
  },
  {
    url: '/marketing-center/mapped-forms',
    label: 'Mapped Forms',
    icon: 'dns',
    createNewUrl: '/marketing-center/mapped-forms/createEdit',
    createNewLabel: 'Mapped Form',
  },
  {
    url: '/marketing-center/landing-pages',
    label: 'Landing Pages',
    icon: 'web',
    createNewUrl: '/marketing-center/landing-pages/createEdit',
    createNewLabel: 'Landing Page',
  },
  {
    url: '/marketing-center/lead-scoring',
    label: 'Lead Scoring',
    icon: 'score',
  },
  {
    url: '/marketing-center/lists',
    label: 'Marketing Lists',
    icon: 'listIcon',
    createNewUrl: '/marketing-center/lists/createEdit',
    createNewLabel: 'List',
  },
  {
    url: '/marketing-center/segments/edit',
    label: 'Segments',
    icon: 'pieChart',
  },
  {
    url: '/marketing-center/socialize',
    label: 'Socialize',
    icon: 'share',
  },
  {
    url: '/marketing-center/subscriptions',
    label: 'Subscription Lists',
    icon: 'playlistAddCheck',
    createNewUrl: '/marketing-center/subscriptions/createEdit',
    createNewLabel: 'Subscription',
  },
  {
    url: '/marketing-center/sms',
    label: 'Text Messages',
    icon: 'sms',
  },
];

export const navItemsWebinars = [
  {
    url: '/marketing-center/webinars',
    label: 'Webinars',
    icon: 'cast',
  },
  {
    url: '/reporting/dashboards/webinar',
    label: 'Webinar Dashboard',
    icon: 'equalizer',
  },
];
